<template>
  <v-container fluid>
  <v-row>
    <v-col
      md=4
      sm=12
      xs=12
    >
    <v-card
        class="mx-auto"
      >
        <v-img
          alt=""
          style="display:none"
          class="shrink mr-2"
          contain
          :src="'/static/illustrations/' + image + '.svg'"
          width="100%"
          icon
        />
        <v-card-title>
            <h1 class='title'>{{title}}</h1>
        </v-card-title>
    </v-card>
    </v-col>

    <v-col
    >
      <div v-if="!$store.state.loading">
        <v-row no-gutters justify="center" align="center">
          <v-col cols="8">
            <v-file-input
              show-size
              label="Arquivo"
              @change="selectFile"
            ></v-file-input>
          </v-col>

          <v-col cols="4" class="pl-2">
            <v-btn color="success" dark small @click="upload">
              Upload
              <v-icon right dark>mdi-cloud-upload</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-alert v-if="message" border="left" color="blue-grey" dark>
          {{ message }}
        </v-alert>

        <v-card v-if="fileInfos.length > 0" class="mx-auto">
          <v-list>
            <v-subheader>List of Files</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item v-for="(file, index) in fileInfos" :key="index">
                <a :href="file.url">{{ file.name }}</a>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </div>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {
  name: "AddLawsuitFile",
  data() {
    return {
      image: '',
      currentFile: undefined,
      message: "",
      fileInfos: [],
      definitions: {
        'kit': {
          title: 'Adicionar Documento',
        },
        'reviews': {
          title: 'Adicionar Minuta',
        },
      }
    }
  },
  computed: {
    role() {
      if (this.roles.map(i => {return i.value}).includes('lawyer')) {
        return 'legal'
      }
      return 'internal'
    },
    roles() {
      return this.$store.getters.profileRoles
    },
    title() {
      return this.definition && this.definition.title ? this.definition.title : ''
    },
    definition() {
      return this.definitions[this.$route.params.file] ? this.definitions[this.$route.params.file] : {}
    },
  },
  methods: {
    selectFile(file) {
      this.currentFile = file
    }, 
    upload() {
      if (!this.currentFile) {
        this.message = "Selecione um arquivo"
        return
      }

      this.message = "";
      const pk = this.$route.params.id
      const file = this.currentFile
      const redir = '/lawsuit/view/' + pk + '/documents'
      const filetype = this.$route.params.file
      let action = 'uploadLawsuitFile'
      if (this.role === 'internal') {
        action = 'uploadLawsuitFile'
      }
      this.$store
        .dispatch(action, {file, filetype, pk})
        .then((r) => {
          this.$store.commit('setReady')
          this.$router.push({path: redir})
          return r
        })
    },         
  }
};
</script>